<template lang="pug">
  li(
    :class="{'grouped-list__item_active': active, 'grouped-list__item_multiple': multiple }"
    @click="$emit('click')"
  ).grouped-list__item
    slot
</template>

<script>
export default {
  props: {
    active: Boolean,
    multiple: {
      type: Boolean,
      default: false
    }
  }
}
</script>